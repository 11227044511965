import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const TxViewSkeleton = () => {
  return (
    <div className="content-wrapper tx-view-skeleton">
      <section className="content">
        <div className="container-fluid">
          {/* Top Button Section */}
          <div className="card mb20 top-btn-section">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 col-lg-3">
                  <Skeleton variant="text" width={120} height={30} className="skeleton-btn" />
                </div>
                <div className="col-md-6 col-lg-6">
                  <Skeleton variant="rectangular" width="100%" height={40} className="skeleton-select" />
                </div>
                <div className="col-md-3 col-lg-3">
                  <Skeleton variant="text" width={120} height={30} className="skeleton-btn" />
                </div>
              </div>
            </div>
          </div>

          {/* Chart Group Section */}
          <div className="row chart-group-section">
            <div className="col-md-3 col-lg-3">
              <div className="card mb20 chart-card">
                <div className="inrCardHdr">
                  <Skeleton variant="text" width={150} height={25} className="skeleton-title" />
                </div>
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={250} className="skeleton-chart" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="card mb20 chart-card">
                <div className="inrCardHdr">
                  <Skeleton variant="text" width={150} height={25} className="skeleton-title" />
                </div>
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={250} className="skeleton-chart" />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="card mb20 chart-card">
                <div className="inrCardHdr">
                  <Skeleton variant="text" width={150} height={25} className="skeleton-title" />
                </div>
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={250} className="skeleton-chart" />
                </div>
              </div>
            </div>
          </div>

          {/* Table Group Section */}
          <div className="row table-group-section">
            <div className="col-md-8 col-lg-8">
              <div className="card mb20 alert-table">
                <div className="inrCardHdr">
                  <Skeleton variant="text" width={150} height={25} className="skeleton-title" />
                </div>
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={200} className="skeleton-table" />
                </div>
              </div>
              <div className="card mb20 parameter-table">
                <div className="inrCardHdr">
                  <Skeleton variant="text" width={150} height={25} className="skeleton-title" />
                </div>
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={200} className="skeleton-table" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="card mb20 relay-table">
                <div className="inrCardHdr">
                  <Skeleton variant="text" width={120} height={25} className="skeleton-title" />
                </div>
                <div className="card-body">
                  <Skeleton variant="rectangular" width="100%" height={400} className="skeleton-table" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TxViewSkeleton;
