import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';

const columns = [
  {
    name: 'Request ID',
    selector: (row) => row.requestId,
    sortable: true,
  },
  {
    name: 'From Date',
    selector: (row) => row.fromDate,
    sortable: true,
  },
  {
    name: 'To Date',
    selector: (row) => row.toDate,
    sortable: true,
  },
  {
    name: 'Status',
    cell: (row) => (
      <div>
        {row.status === 'Inprogress' ? (
          <div>
            <div>
              <span>File 1</span>
              {/* <span className="status-icon text-success">✅</span> */}
              <span className='float-right'><img src='assets/images/comlpete.png'
              alt="completed"
              width="20px"
              ></img></span>
            </div>
            <div>
              <span>File 2</span>
              {/* <span className="status-icon text-warning">⏰</span> */}
              <span className='float-right'><img src='assets/images/progress.png'
              alt="In progress"
              width="20px"
              ></img></span>
            </div>
          </div>
        ) : (
          row.status
        )}
      </div>
    ),
  },
  {
    name: 'Download',
    cell: (row) => (
      <a href='' className="">
        <i className="fa fa-download"></i>
      </a>
    ),
  },
];

const fileList = [
  {
    requestId: 'RET324257',
    fromDate: '01/12/2024',
    toDate: '31/12/2024',
    status: 'Inprogress',
  },
  {
    requestId: 'RET123805',
    fromDate: '01/09/2024',
    toDate: '30/09/2024',
    status: 'Pending',
  },
  {
    requestId: 'RET86846',
    fromDate: '01/08/2024',
    toDate: '31/08/2024',
    status: 'Completed',
  },
  {
    requestId: 'RET8327',
    fromDate: '01/07/2024',
    toDate: '28/07/2024',
    status: 'Completed',
  },
];

function Historicaldata() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleDateChange = (setter) => (date) => {
    setter(date);
  };

  return (
    <div className="content-wrapper">
      {/* <header className="content">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Historical Data</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Historical Data</li>
              </ol>
            </div>
          </div>
        </div>
      </header> */}

      <section className="content">
        <div className="container-fluid">
          {/* Filters Section */}
          <div className="row ">
            <div className="col-md-12 col-lg-12">
              <div className="historicalpg mdlContent card pr-card mb30">
                  <div className="breadcrumbHdr card-header">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Historicaldata</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <a href="#">Historicaldata</a>
                      </li>
                    </ol>
                  </div>
                  <div className="mdlContent card-body">
              <div className="card">
                <div className="card-body">
                  <form className="form-inline">
                    <div className="form-group mr-2">
                      <label htmlFor="fromDate" className="mr-2">
                        From Date
                      </label>
                      <DatePicker
                        selected={fromDate}
                        onChange={handleDateChange(setFromDate)}
                        className="form-control"
                        id="fromDate"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div className="form-group mr-2">
                      <label htmlFor="toDate" className="mr-2">
                        To Date
                      </label>
                      <DatePicker
                        selected={toDate}
                        onChange={handleDateChange(setToDate)}
                        className="form-control"
                        id="toDate"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            

          {/* Table Section */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                {/* <div className="card-header">
                  <h3 className="card-title">Historical Data Table</h3>
                </div> */}
                <div className="card-body">
                  <DataTable
                    columns={columns}
                    data={fileList.map((item) => ({
                      ...item,
                      fromDate: format(
                        parse(item.fromDate, 'dd/MM/yyyy', new Date()),
                        'dd/MM/yyyy'
                      ),
                      toDate: format(
                        parse(item.toDate, 'dd/MM/yyyy', new Date()),
                        'dd/MM/yyyy'
                      ),
                    }))}
                    pagination
                    highlightOnHover
                    className="table table-hover dtr-inline"
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </section>
    </div>
  );
}

export default Historicaldata;
