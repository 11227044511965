import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Image from "../../../assets/images/full_screen.png";
import exitImage from "../../../assets/images/full_screen_exit.png";
import TxViewSkeleton from "./TxViewSkeleton";
import RelaySkeleton from "./RelaySkeleton";
import ElectricalSkeleton from "./ElectricalSkeleton";
import Plot from "react-plotly.js";

function TxView() {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [location, setLocation] = useState("");
  const [asset, setAsset] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const navigate = useNavigate(); // Initialize navigation
  const [isChecked, setIsChecked] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState(null); // Selected asset
  const [relaysData, setRelaysData] = useState([]); // State for relays and alerts
  const [viewMode, setViewMode] = useState("24_hour"); // Default to "24_hour"
  const [relaysAndAlertsViewMode, setRelaysAndAlertsViewMode] =
    useState("24_hour"); // Default view mode
  const [electricalParams, setElectricalParams] = useState([]);
  const [isRelaysLoading, setIsRelaysLoading] = useState(false);
  const [isElectricalLoading, setIsElectricalLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // Track if the carousel is hovered

  const [assetCountState, setAssetCount] = useState(0); // 
  // 
  // Initialize as 0

  const handleMouseEnter = () => {
    setIsHovered(true); // Pause the auto-slide
  };

  const handleMouseLeave = () => {
    setIsHovered(false); // Resume the auto-slide
  };


  useEffect(() => {
    const getAssetCountAndNavigate = async () => {
      if (!token) return; // Exit if no token

      let attempts = 0;
      const maxAttempts = 3;

      while (attempts < maxAttempts) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/total_assets_count`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const assetCount = response.data.data;
            setAssetCount(assetCount); // Set the asset count
            if (assetCount <= 1) {
              navigate("/transformer TxView");
            }
            return; // Stop retrying after success
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
        attempts++;
      }

      // Handle failure after maxAttempts
      console.warn("Failed to fetch asset count after maximum attempts.");
    };

    getAssetCountAndNavigate();
  }, [token, navigate]);

  const handleViewModeToggle = () => {
    const newMode =
      relaysAndAlertsViewMode === "24_hour" ? "real_time" : "24_hour";
    setRelaysAndAlertsViewMode(newMode);

    // Trigger the API call with the new view mode
    const filterMode = Cookies.get("filterMode") || "0";
    const currentLocation = locationData[currentPage];

    if (currentLocation) {
      fetchRelaysAndAlerts(
        filterMode,
        newMode, // Pass the new view mode
        currentLocation.location_id,
        currentLocation.assset_id
      );
    }
  };

  const handleViewModeToforElectrical = () => {
    const newMode = viewMode === "24_hour" ? "real_time" : "24_hour";
    setViewMode(newMode);

    // Fetch updated data based on the new view mode
    const filterMode = Cookies.get("filterMode") || "0";
    const currentLocation = locationData[currentPage];

    if (currentLocation) {
      fetchElectricalParameters(
        filterMode,
        newMode, // Pass the updated view mode
        currentLocation.location_id,
        currentLocation.assset_id
      );
    }
  };

  const fetchRelaysAndAlerts = async (
    filterMode,
    viewMode,
    locationId,
    assetId
  ) => {
    if (!token) {
      console.error("Token is missing");
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsRelaysLoading(true); // Start loading for Relays
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/relays_and_alerts_list`,
          {
            access_token: token,
            user_log_id: userLogId,
            iot_sol: "Transformer",
            critical_asset: filterMode,
            view_mode: viewMode, // Pass the view mode dynamically
            location_id: locationId,
            assset_id: assetId, // Include asset_id
          }
        );

        if (response.status === 200 && response.data.success) {
          setRelaysData(response.data.data); // Update state with response data
          success = true; // Mark success
        } else {
          console.error("No data available for Relays and Alerts List.");
          setRelaysData([]); // Clear state if no data is available
        }
      } catch (error) {
        console.error("Error fetching Relays and Alerts List:", error.message);
        setRelaysData([]); // Clear state on error
      } finally {
        setIsRelaysLoading(false); // Reset loading state
        attempts++; // Increment attempts
      }
    }

    if (!success) {
      showFlashMessage(
        "Data Not Available For Relays and Alerts List",
        "danger"
      );
    }
  };

  const fetchElectricalParameters = async (
    filterMode,
    viewMode,
    locationId,
    assetId
  ) => {
    if (!token) {
      console.error("Token is missing");
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsElectricalLoading(true); // Start loading for Electrical Parameters
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/electrical_cockpit`, // Use your provided endpoint
          {
            access_token: token,
            user_log_id: userLogId,
            iot_sol: "Transformer",
            critical_asset: filterMode,
            view_mode: viewMode,
            location_id: locationId,
            assset_id: assetId,
          }
        );

        if (response.status === 200 && response.data.success) {
          setElectricalParams(response.data.data); // Save response data to state
          success = true;
        } else {
          console.error("No data available for Electrical Parameters.");
          setElectricalParams([]); // Clear state
        }
      } catch (error) {
        console.error("Error fetching Electrical Parameters:", error.message);
        setElectricalParams([]); // Clear state on error
      } finally {
        setIsElectricalLoading(false);
        attempts++;
      }
    }

    if (!success) {
      showFlashMessage(
        "Data Not Available For Electrical Parameters",
        "danger"
      );
    }
  };

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");
    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
      setLocation(tokenData.location);
      setAsset(tkData.asset);
    }
  }, []);

  // Handle the change of the toggle button
  const changeToggleButton = (checked) => {
    if (assetCountState <= 1) {
      return; // Prevent toggle and navigation if assetCount is 1 or less
    }
    setIsChecked(checked); // Update the switch state
    // Navigate based on the switch state
    if (checked) {
      navigate("/Transformer Fleet"); // Navigate to Fleet View if switch is ON
    } else {
      navigate("/Transformer TxView"); // Navigate to TX View if switch is OFF
    }
  };

  // Handle the view toggle for text display (not the redirection)
  const handleViewToggle = (viewType) => {
    if (assetCountState <= 1) {
      return; // Prevent toggle and navigation if assetCount is 1 or less
    }
    if (viewType === "Fleet") {
      navigate("/Transformer Fleet");
    } else if (viewType === "TX") {
      navigate("/Transformer TxView");
    }
  };

  ///////// critical/all switch ////////////
  const [isCritical, setIsCritical] = useState(false);
  // Read the initial value from cookies when the component loads
  useEffect(() => {
    const cookieValue = Cookies.get("filterMode");
    if (cookieValue) {
      setIsCritical(cookieValue === "1");
    }
  }, []);

  const handleToggleChange = async (checked) => {
    const newValue = !isCritical ? "1" : "0";
    setIsCritical(!isCritical);
    Cookies.set("filterMode", newValue);
    await getLocation(newValue);
    // await getLoadingTemp(newValue);
    // await getGraphData(newValue);
    // await getLatestAlerts(newValue);
  };

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  /////////Location Asset name////////
  const [currentPage, setCurrentPage] = useState(0);

  const getLocation = async (filterMode) => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/fetch_location_and_assets`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Transformer",
              critical_asset: filterMode,
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data;
            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            // data.forEach((item) =>
            //   getLoadingTemp(filterMode, item.location_id, item.assset_id)
            // );
            // const firstItem = response.data.data[0];
            // if (firstItem) {
            //   getGraphData(filterMode, firstItem.location_id, firstItem.assset_id);
            // }
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  //////// box plot chart //////////
  const [originalSeries, setOriginalSeries] = useState([
    {
      name: "Loading (%)",
      data: [],
    },
    {
      name: "Winding Temp (°C)",
      data: [],
    },
  ]);

  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });
  // Function to calculate the global min and max across both Loading and Winding series
  const calculateGlobalMinMax = () => {
    // Combine all y-values from both series
    const allValues = [
      ...originalSeries[0].data.flatMap((item) => item.y),
      ...originalSeries[1].data.flatMap((item) => item.y),
    ];

    // Get the global min and max values
    const globalMin = Math.min(...allValues);
    const globalMax = Math.max(...allValues);

    // Return the global min and max with ±5 adjustment
    return { min: globalMin, max: globalMax };
  };

  // const [options, setOptions] = useState({
  //   chart: {
  //     type: "boxPlot",
  //     toolbar: {
  //       show: false, // Disables all toolbar icons
  //     },
  //     animations: {
  //       enabled: false,
  //     },
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 1921,
  //       options: {
  //         chart: {
  //           height: 190,
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 1601,
  //       options: {
  //         chart: {
  //           height: 140,
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 1537,
  //       options: {
  //         chart: {
  //           height: 190,
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 1367,
  //       options: {
  //         chart: {
  //           height: 150,
  //         },
  //       },
  //     },
  //   ],
  //   legend: {
  //     markers: {
  //       fillColors: ["#1C75BD", "#8EC5EC"],
  //     },
  //     position: "top", // Place the legend on top
  //   },
  //   xaxis: {
  //     type: "category",
  //     labels: {
  //       show: false,
  //       style: {
  //         fontWeight: "900px", // Bold labels
  //       },
  //     },
  //     style: { fontWeight: 600 },
  //   },
  //   yaxis: [
  //     {
  //       labels: {
  //         formatter: function (value) {
  //           return value !== null && !isNaN(value) ? value.toFixed(2) : "";
  //         },
  //         style: {
  //           color: "#000000",
  //           fontFamily: "Montserrat, sans-serif",
  //           fontSize: "10px",
  //           fontWeight: 500,
  //         },
  //       },
  //       title: {
  //         text: "Loading (%)",
  //       },
  //       opposite: false, // Left side
  //     },
  //     {
  //       labels: {
  //         formatter: function (value) {
  //           return value !== null && !isNaN(value) ? value.toFixed(2) : "";
  //         },
  //         style: {
  //           color: "#000000",
  //           fontFamily: "Montserrat, sans-serif",
  //           fontSize: "10px",
  //           fontWeight: 500,
  //         },
  //       },
  //       title: {
  //         text: "Winding Temp (Â°C)",
  //       },
  //       opposite: true, // Right side
  //     },
  //   ],
  //   title: {
  //     text: "",
  //   },
  //   plotOptions: {
  //     boxPlot: {
  //       colors: {
  //         upper: undefined, // Not used because we will set colors per series
  //         lower: undefined,
  //       },
  //       stroke: {
  //         width: 0, // Removes the border between boxes
  //       },
  //     },
  //   },
  //   fill: {
  //     colors: ["#1C75BD", "#8EC5EC"], // Set specific colors for each series
  //   },
  //   tooltip: {
  //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //       const seriesName = w.globals.seriesNames[seriesIndex];
  //       const data =
  //         w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
  //       const [close, low, average, high, open] = data; // Using array destructuring

  //       return `
  //      <div style="padding: 10px; border: 1px solid #ddd; background: white;">
  //        <strong>${seriesName}</strong><br/>
  //        <div>Max: ${open.toFixed(2)}</div>
  //        <div>Q3: ${high.toFixed(2)}</div>
  //        <div>Median: ${average.toFixed(2)}</div>
  //        <div>Q1: ${low.toFixed(2)}</div>
  //        <div>Min: ${close.toFixed(2)}</div>
  //      </div>
  //    `;
  //     },
  //   },
  // });

  const getLoadingTemp = async (filterMode, locationId, assetId) => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    setIsLoading(true);
    try {
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/loading_utilization_and_temperature_stress`,
          {
            access_token: token,
            user_log_id: userLogId,
            iot_sol: "Transformer",
            critical_asset: filterMode,
            location_id: locationId,
            assset_id: assetId,
          }
        );

        if (response.status === 200 && response.data.success) {
          const data = response.data.data;

          if (data && data.length > 0) {
            // const loadingSeries = data.find((s) => s.name === "Loading (%)");
            // const temperatureSeries = data.find((s) => s.name === "Winding (Â°C)");

            // // Handle y-axis range dynamically
            // const getGlobalMinMax = (seriesData, offset = 1) => {
            //   const allYValues = seriesData?.flatMap((d) => d.y || []) || [];
            //   if (allYValues.length === 0) return { min: 0, max: 0 };
            //   const min = Math.min(...allYValues) - offset;
            //   const max = Math.max(...allYValues) + offset;
            //   return { min, max };
            // };

            // const loadingRange = getGlobalMinMax(loadingSeries?.data);
            // const temperatureRange = getGlobalMinMax(temperatureSeries?.data);

            // const yaxis = [];
            // if (loadingSeries) {
            //   yaxis.push({
            //     seriesName: "Loading (%)",
            //     min: loadingRange.min,
            //     max: loadingRange.max,
            //     labels: {
            //       formatter: (value) =>
            //         value !== null && !isNaN(value) ? value.toFixed(2) : "",
            //       style: {
            //         color: "#000000",
            //         fontSize: "10px",
            //         fontWeight: 500,
            //       },
            //     },
            //     title: { text: "Loading (%)" },
            //   });
            // }

            // if (temperatureSeries) {
            //   yaxis.push({
            //     seriesName: "Winding (Â°C)",
            //     min: temperatureRange.min,
            //     max: temperatureRange.max,
            //     opposite: true,
            //     labels: {
            //       formatter: (value) =>
            //         value !== null && !isNaN(value) ? value.toFixed(2) : "",
            //       style: {
            //         color: "#000000",
            //         fontSize: "10px",
            //         fontWeight: 500,
            //       },
            //     },
            //     title: { text: "Winding Temp (Â°C)" },
            //   });
            // }

            // setOptions((prevState) => ({
            //   ...prevState,
            //   yaxis,
            // }));

            setOriginalSeries(response.data.data);
          } else {
            // No data: Display empty chart
            setOriginalSeries([
              {
                name: "Loading (%)",
                data: [],
              },
              {
                name: "Winding Temp (°C)",
                data: [],
              },
            ]);
          }
        } else {
          setOriginalSeries([
            {
              name: "Loading (%)",
              data: [],
            },
            {
              name: "Winding Temp (°C)",
              data: [],
            },
          ]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOriginalSeries([
        {
          name: "Loading (%)",
          data: [],
        },
        {
          name: "Winding Temp (°C)",
          data: [],
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const globalRange = calculateGlobalMinMax();
    //     setOptions({
    //       chart: {
    //         type: "boxPlot",
    //         toolbar: {
    //             show: false,
    //         },
    //         animations: {
    //           enabled: false,
    //         },
    //       },
    //       responsive: [
    //       {
    //         breakpoint: 1921,
    //         options: {
    //           chart: {
    //             height: 190,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1601,
    //         options: {
    //           chart: {
    //             height: 140,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1537,
    //         options: {
    //           chart: {
    //             height: 190,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1367,
    //         options: {
    //           chart: {
    //             height: 150,
    //           },
    //         },
    //       },
    //       {
    //         breakpoint: 1281,
    //         options: {
    //           chart: {
    //             height: 130,
    //           },
    //         },
    //       },
    //     ],
    //       legend: {
    //         markers: {
    //           fillColors: ["#1C75BD", "#8EC5EC"],
    //         },
    //         position: "top", // Place the legend on top
    //       },
    //       xaxis: {
    //         type: "category",
    //         labels: {
    //           show: false,
    //           style: {
    //             fontWeight: "900px", // Bold labels
    //           },
    //         },
    //         style: { fontWeight: 600 },
    //       },
    //       yaxis: [
    //         {
    //           labels: {
    //             // formatter: function (value) {
    //             //   return value !== null && !isNaN(value) ? value : "";
    //             // },
    //             style: {
    //               color: "#000000",
    //               fontFamily: "Montserrat, sans-serif",
    //               fontSize: "10px",
    //               fontWeight: 500,
    //             },
    //           },
    //           title: {
    //             text: "Loading (%)",
    //           },
    //           min: globalRange.min, // Use global min
    //           max: globalRange.max, // Use global max
    //           opposite: false, // Left side
    //         },
    //         {
    //           labels: {
    //             // formatter: function (value) {
    //             //   return value !== null && !isNaN(value) ? value : "";
    //             // },
    //             style: {
    //               color: "#000000",
    //               fontFamily: "Montserrat, sans-serif",
    //               fontSize: "10px",
    //               fontWeight: 500,
    //             },
    //           },
    //           title: {
    //             text: "Winding Temp (°C)",
    //           },
    //           min: globalRange.min, // Use global min
    //           max: globalRange.max, // Use global max
    //           opposite: true, // Right side
    //         },
    //       ],
    //       title: {
    //         text: "",
    //       },
    //       plotOptions: {
    //         boxPlot: {
    //           colors: {
    //             upper: undefined, // Not used because we will set colors per series
    //             lower: undefined,
    //           },
    //           stroke: {
    //             width: 0, // Removes the border between boxes
    //           },
    //         },
    //       },
    //       fill: {
    //         colors: ["#1C75BD", "#8EC5EC"], // Set specific colors for each series
    //       },
    //    tooltip: {
    //       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //         const seriesName = w.globals.seriesNames[seriesIndex];
    //         const data =
    //           w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
    //         const [close, low, average, high, open] = data; // Using array destructuring
    //  // Check if any value in the data is null
    //  if (data.includes(null)) {
    //   return ''; // Return an empty string to prevent showing the tooltip
    // }
    //         return `
    //       <div style="border: 1px solid #ddd; background: white;">
    //  <div style="background-color: #e0e4e9; width: 100%; text-align: center; padding: 5px 0; font-size:0.75rem; font-weight: bold; ">
    //           ${seriesName}
    //         </div>
    //          <div style="padding: 5px; text-align:left; font-size:0.688rem;">
    //         <div>Max:<strong> ${open}</strong></div>
    //              <div>Q3: <strong> ${high}</strong></div>
    //              <div>Median: <strong> ${average}</strong></div>
    //              <div>Q1: <strong> ${low}</strong></div>
    //              <div>Min: <strong> ${close}</strong></div>
    //            </div>
    //            </div>
    //      `;
    //       },
    //     },
    //     });
    // Update the series
    setSeries(originalSeries);
  }, [originalSeries]);

  const calculateSeriesMinMax = (seriesName) => {
    const series = originalSeries.find((item) => item.name === seriesName);
    if (!series || !series.data) return { min: 0, max: 0 };

    const allValues = series.data.flatMap((item) => item.y);
    const min = Math.min(...allValues) 
    const max = Math.max(...allValues) 
    return { min, max };
  };

  const loadingData = originalSeries.find(
    (item) => item.name === "Loading (%)"
  );
  const windingData = originalSeries.find(
    (item) => item.name === "Winding Temp (°C)"
  );

  const plants = loadingData.data.map((d) => d.x); // Transformer names
  const loadingY = loadingData.data.map((d) => d.y); // Loading values for each transformer
  const windingY = windingData.data.map((d) => d.y); // Winding temp values for each transformer

  const plotData = [
    // Grouped Box plot for all Loading (%)
    {
      y: [].concat(...loadingY), // Flatten all loading data values
      x: [].concat(
        ...loadingY.map((_, index) =>
          Array(loadingY[index].length).fill(plants[index])
        )
      ), // Flatten the x labels
      type: "box",
      name: "Loading (%)",
      boxmode: "group",
      marker: { color: "#1C75BD" }, // Custom color for Loading (%)
      legendgroup: "loading", // Group under "Loading"
      hoverinfo: "none", // Disable default hover info
    },

    // Grouped Box plot for all Winding Temp (°C)
    {
      y: [].concat(...windingY), // Flatten all winding data values
      x: [].concat(
        ...windingY.map((_, index) =>
          Array(windingY[index].length).fill(plants[index])
        )
      ), // Flatten the x labels
      type: "box",
      name: "Winding (°C)",
      boxmode: "group",
      yaxis: "y2",
      marker: { color: "#8EC5EC" }, // Custom color for Winding Temp (°C)
      legendgroup: "winding", // Group under "Winding"
      hoverinfo: "none", // Disable default hover info
    },
  ];

  const handleHover = (event) => {
    const points = event.points[0];

    const color = points.data.marker.color; // Retrieve the marker color for the legend symbol

    setTooltip({
      visible: true,
      content: `
        <div style="display: flex; align-items: center;background-color: lightgrey;font-family: 'Montserrat', sans-serif;">
          <div style="background-color: lightgrey; border-radius: 2px; padding: 5px 15px;">
<span style="  display: inline-block; margin-right: 5px;align-items: center;"></span>
 <b>${points.data.name}</b>
          </div>
        </div>
<div style="margin:0 10px">
        Max:  <strong>${points.data.y[4]}</strong>
        <br>
        Q3:   <strong>${points.data.y[3]}</strong>
         <br>
        Median:  <strong>${points.data.y[2]}</strong>
         <br>
        Q1: <strong>${points.data.y[1]}</strong>
         <br>
        Min: <strong>${points.data.y[0]}</strong>
        </div>
        `,
      x: event.event.clientX,
      y: event.event.clientY,
    });
  };

  const handleUnhover = () => {
    setTooltip({ visible: false, content: "", x: 0, y: 0 });
  };

  // line chart
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    // Update selected asset whenever the slide changes
    if (locationData[currentPage]) {
      setSelectedAsset(locationData[currentPage].assset_id);
    }
  }, [currentPage, locationData]);

  const handleLocationAssetClick = (locationId, assetId) => {
    const filterMode = Cookies.get("filterMode") || "0";
    getLoadingTemp(filterMode, locationId, assetId);
    getGraphData(filterMode, locationId, assetId);
    getLatestAlerts(filterMode, locationId, assetId);
    getBarData(filterMode, locationId, assetId);

    // Add relays and alerts API call
    fetchRelaysAndAlerts(
      filterMode,
      relaysAndAlertsViewMode,
      locationId,
      assetId
    );
    fetchElectricalParameters(filterMode, viewMode, locationId, assetId);

    const selectedIndex = locationData.findIndex(
      (item) => item.location_id === locationId && item.assset_id === assetId
    );
    if (selectedIndex !== -1) {
      setCurrentPage(selectedIndex);
    }
  };

  const [chartOption, setChartOption] = useState({
    colors: ["#39B54A", "#F15A2A", "#2A73B9", "#F7941E"],
    chart: {
      toolbar: {
        show: false, // Disables all toolbar icons
      },
      // toolbar: {
      //   export: {
      //     csv: {
      //       filename: "Transformer Trend",
      //       headerCategory: "Date Time",
      //       categoryFormatter: function (timestamp) {
      //         let date = new Date(timestamp).toUTCString();
      //         const originalDate = new Date(date);

      //         // Add 5 hours and 30 minutes to the original date
      //         originalDate.setHours(originalDate.getHours() - 5);
      //         originalDate.setMinutes(originalDate.getMinutes() - 30);

      //         // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
      //         const formattedDate =
      //           ("0" + originalDate.getDate()).slice(-2) +
      //           "-" +
      //           ("0" + (originalDate.getMonth() + 1)).slice(-2) +
      //           "-" +
      //           originalDate.getFullYear() +
      //           " " +
      //           ("0" + originalDate.getHours()).slice(-2) +
      //           ":" +
      //           ("0" + originalDate.getMinutes()).slice(-2) +
      //           ":" +
      //           ("0" + originalDate.getSeconds()).slice(-2);
      //         return formattedDate;
      //       },
      //     },
      //     png: {
      //       filename: "Transformer Trend",
      //     },
      //     svg: {
      //       filename: "Transformer Trend",
      //     },
      //   },
      // },
      animations: {
        enabled: false,
      },
      // height: "260px",
      width: "100%",

      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 1921,
        options: {
          chart: {
            height: 190,
          },
        },
      },
      {
        breakpoint: 1601,
        options: {
          chart: {
            height: 140,
          },
        },
      },
      {
        breakpoint: 1537,
        options: {
          chart: {
            height: 190,
          },
        },
      },
      {
        breakpoint: 1367,
        options: {
          chart: {
            height: 150,
          },
        },
      },
      {
        breakpoint: 1281,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    legend: {
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "center",
      fontFamily: "Montserrat, sans-serif", // Set font family for legend
      fontSize: "14px", // Set font size for legend
      fontWeight: 600,
    },
    // legend: {
    //   position: 'top',
    //   horizontalAlign: 'center',
    //   // fontSize: '14px'
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      // min: minValue,
      // max: maxValue,
      title: {
        text: "Temperature(°C)", // Set your Y-axis title here
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: "1rem", // Set font size for Y-axis title
          fontWeight: 700,
        },
      },
      labels: {
        formatter: function (value) {
          if (value !== null && typeof value !== "undefined" && !isNaN(value)) {
            return value.toFixed(2); // Format y-axis labels to 2 decimal places
          } else {
            return ""; // Return an empty string for undefined or NaN values
          }
        },
        style: {
          fontFamily: "Montserrat, sans-serif",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        // formatter: function (value) {
        //   const hours = Math.floor(value); // Get the hour
        //   return `${hours < 10 ? "0" + hours : hours}:00`; // Add leading zero if single-digit hour
        // },
        datetimeFormatter: {
          hour: 'HH:mm'
        },
        style: {
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      x: {
        format: 'HH:mm',
        // formatter: function (value) {
        //   const hours = Math.floor(value); // Get the hour
        //   return `${hours < 10 ? "0" + hours : hours}:00`; // Add leading zero if single-digit hour
        // },
      },
      cssClass: "custom-tooltip",
      style: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
      },
    },
  });
  const getAdjustedYAxisRange = (series) => {
    // Extract all Y-values, filtering out null or undefined values
    const allYValues = series
      .flatMap((s) => s.data.map((point) => point.y))
      .filter((y) => typeof y === "number" && !isNaN(y)); // Exclude null, undefined, and non-numeric values

    if (allYValues.length === 0) {
      // In case all values are null/undefined, we can handle it by returning a default range
      return { min: 0, max: 1 }; // You can set a default range here
    }

    // Calculate min and max values, now considering only valid (non-null) values
    const minY = Math.min(...allYValues) - 0.1;
    const maxY = Math.max(...allYValues) + 0.1;

    return { min: minY, max: maxY };
  };

  const getGraphData = async (filterMode, locationId, assetId) => {
    if (!token) return;

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/temperature_cockpit`,
        {
          access_token: token,
          user_log_id: userLogId,
          iot_sol: "Transformer",
          critical_asset: filterMode,
          location_id: locationId,
          assset_id: assetId,
        }
      );

      if (response) {
        const rawData = response.data.data[0];
        const transformerKey = Object.keys(rawData)[0];
        const transformerData = rawData[transformerKey];

        const seriesData = transformerData.map((tempCategory) => ({
          name: tempCategory.name,
          data: tempCategory.data.map(([x, y]) => ({ x, y: y || 0 })),
        }));
        // Get adjusted Y-axis range based on the data
        const { min, max } = getAdjustedYAxisRange(seriesData);

        // Update chart options with dynamic Y-axis min/max values
        setChartOption((prevOptions) => ({
          ...prevOptions,
          yaxis: {
            ...prevOptions.yaxis,
            min: min,
            max: max,
          },
        }));

        setChartSeries(seriesData);
      } else {
        setChartSeries([]);
        setIsLoading(false);
      }
    } catch (error) {
      setChartSeries([]);
      console.log(error);
      setIsLoading(false);
    }
  };

  //////// bar chart//////////
  const [chartDatas, setChartDatas] = useState([]);
  // const [isEnergyConsumptionAvailable, setIsEnergyConsumptionAvailable] =
  //   useState(true);
  const getBarData = async (filterMode, locationId, assetId) => {
    if (!token) return;

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/benchmark_vs_actual_energy_consumption`,
        {
          access_token: token,
          user_log_id: userLogId,
          iot_sol: "Transformer",
          critical_asset: filterMode,
          location_id: locationId,
          assset_id: assetId,
        }
      );
      if (response.status === 200 && response.data.success) {
        const rawData = response.data.data;

        setChartDatas(rawData);
        // setIsEnergyConsumptionAvailable(true);

      } else {
        setChartDatas([]);
        // setIsEnergyConsumptionAvailable(false);
        setIsLoading(false);
      }
    } catch (error) {
      setChartDatas([]);
      // setIsEnergyConsumptionAvailable(false);
      console.log(error);
      setIsLoading(false);
    }
  };

  const chartDataArray = chartDatas || [];
  const labels = chartDataArray.map((item) => item.asset);

  const consumptionValues = chartDataArray.map((item) =>
    item.actual_energy_delivered_kwh.toFixed(1)
  );

  const benchmarkValues = chartDataArray.map((item) =>
    item.benchmark_energy_delivered_kwh.toFixed(1)
  );

  const allValues = [
    ...chartDataArray.map((item) => item.actual_energy_delivered_kwh),
    ...chartDataArray.map((item) => item.benchmark_energy_delivered_kwh),
  ];
  let barMinValue = Math.min(...allValues) - 0.1;
  barMinValue = barMinValue < 0 ? 0 : barMinValue;

  const barMaxValue = Math.max(...allValues) + 0.1;

  const chartDataMain = {
    options: {
      chart: {
        toolbar: {
          show: false, // Disables all toolbar icons
        },
        // toolbar: {
        //   export: {
        //     csv: {
        //       filename: "Energy Transferred (kWh)",
        //       headerCategory: "Date",
        //       categoryFormatter: (value) => value,
        //     },
        //     png: {
        //       filename: "Energy Transferred (kWh)",
        //     },
        //     svg: {
        //       filename: "Energy Transferred (kWh)",
        //     },
        //   },
        // },
        animations: {
          enabled: false,
        },
        type: "bar",
        // height: 350,
      },
      responsive: [
        {
          breakpoint: 1921,
          options: {
            chart: {
              height: 190,
            },
          },
        },
        {
          breakpoint: 1601,
          options: {
            chart: {
              height: 140,
            },
          },
        },
        {
          breakpoint: 1537,
          options: {
            chart: {
              height: 190,
            },
          },
        },
        {
          breakpoint: 1367,
          options: {
            chart: {
              height: 150,
            },
          },
        },
        {
          breakpoint: 1281,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false, // Enable data labels
        offsetY: -20,
        style: {
          fontSize: "12px", // Set the font size for the labels
          fontFamily: "Montserrat", // Use the Montserrat font
          colors: ["#000000"], // Set the color of the labels
        },
        formatter: function (val) {
          return val; // Display the value as-is
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#7BBFE9", "#1B75BB"],
      },
      xaxis: {
        categories: labels,
        title: {
          // text: "Transformer",
          style: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        labels: {
          show: false, // Hide the bottom labels
        },
        // labels: {
        //   show: labels.length > 0,
        //   style: {
        //     fontFamily: "Montserrat",
        //     fontSize: "14px",
        //     fontWeight: "500",
        //   },
        //   formatter: (value) => value,
        // },
      },
      yaxis: {
        min: barMinValue,
        max: Math.ceil(barMaxValue / 10000) * 10000,
        labels: {
          formatter: function (value) {
            if (
              value !== null &&
              typeof value !== "undefined" &&
              !isNaN(value) &&
              value === 0
            ) {
              return "0"; // Display "0" without "k"
            }
            return `${Math.round(value / 1000)}k`; // Divide by 1000, round to nearest integer, and append "k"
          },
        },
        title: {
          style: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "500",
            color: "#000000",
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#7BBFE9", "#1B75BB"],
        type: ["solid", "solid"],
        markers: {
          colors: ["#7BBFE9", "#1B75BB"],
        },
      },
      tooltip: {
        cssClass: "custom-tooltip",
        y: {
          formatter: function (val) {
            return val;
          },
          style: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          style: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        markers: {
          fillColors: ["#7BBFE9", "#1B75BB"],
        },
      },
      grid: {
        padding: {
          bottom: 20,
        },
      },
    },
    series: [
      {
        name: "Benchmark",
        data: benchmarkValues,
        // data: consumptionValues,
      },
      {
        name: "Actual",
        data: consumptionValues,
      },
    ],
  };

  /////////latest alerts ////////
  const [alertHeaders, setAlertHeaders] = useState([]);
  const [alertRows, setAlertRows] = useState([]);

  const getLatestAlerts = async (filterMode, locationId, assetId) => {
    if (!token) {
      // Return early if token is not available
      return;
    }
    setIsLoading(true);
    try {
      if (token !== null && token !== undefined && token !== "") {
        const response = await axios.post(
          `${process.env.REACT_APP_VARIABLE}/iotrms/fleet/fleet_alerts`,
          {
            access_token: token,
            user_log_id: userLogId,
            iot_sol: "Transformer",
            critical_asset: filterMode,
            location_id: locationId,
            assset_id: assetId,
          }
        );
        if (response.status === 200 && response.data.success === true) {
          if (response.data.success === true) {
            setAlertHeaders(response.data.data.headers);
            setAlertRows(response.data.data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            // showFlashMessage("Data Not Available");
            setAlertHeaders([]);
            setAlertRows([]);
          }
        } else {
          setAlertHeaders([]);
          setAlertRows([]);
          setIsLoading(false);
          // showFlashMessage("Data Not Available");
        }
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Define the new sequence order
  const reorderedHeaders = [
    "datetime",
    // "location",
    // "assset",
    "parameter_name",
    "parameter_value",
    "message",
  ];

  // Header display names
  const headerNames = [
    "Date Time",
    // "Location",
    // "Asset",
    "Parameter",
    "Value",
    "Alert",
  ];

  // Reorder the rows based on the new sequence
  const reorderedRows = alertRows.map((row) =>
    reorderedHeaders.map((header) => row[alertHeaders.indexOf(header)])
  );

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    // Extract date components
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Extract time components
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Determine AM/PM and adjust hours
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, 0 becomes 12
    hours = String(hours).padStart(2, "0");

    // Format the date and time
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  useEffect(() => {
    if (token) {
      const initialFilterMode = Cookies.get("filterMode") || "0";
      getLocation(initialFilterMode);
    }
  }, [token]);

  ///////// Function to enter fullscreen ///////////
  const [isFullscreen, setIsFullscreen] = useState(false);

  const enterFullScreen = (e) => {
    // e.preventDefault();
    document.documentElement
      .requestFullscreen()
      .then(() => {
        // Remove specific elements
        const elementsToRemove = document.querySelectorAll(
          ".sidebarLeft, .main-sidebar, .sidebarRight"
        );
        elementsToRemove.forEach((element) => {
          element.remove();
        });

        // Reset margin styles for the content-wrapper
        const contentWrapper = document.querySelector(".content-wrapper");
        if (contentWrapper) {
          contentWrapper.style.marginLeft = "0";
          contentWrapper.style.marginRight = "0";
        }
        const mainHeader = document.querySelector(".main-header");
        if (mainHeader) {
          mainHeader.style.marginLeft = "0";
        }

        // Update fullscreen state
        setIsFullscreen(true);
        sessionStorage.setItem("isFullscreen", "true"); // Persist fullscreen state
      })
      .catch((err) => {
        console.error("Failed to enter full-screen mode:", err);
      });
  };

  const exitFullScreen = () => {
    window.location.reload();
    setIsFullscreen(false);
    sessionStorage.removeItem("isFullscreen"); // Remove fullscreen state
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && isFullscreen) {
        exitFullScreen();
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [isFullscreen]);

  useEffect(() => {
    // Check if fullscreen was previously set
    const fullscreenState = sessionStorage.getItem("isFullscreen");
    if (fullscreenState === "true") {
      setIsFullscreen(true);
      enterFullScreen(); // Re-enter fullscreen if necessary
    }
  }, []);

  // Ensure fullscreen is entered when the component is mounted and not in fullscreen already
  useEffect(() => {
    if (isFullscreen && !document.fullscreenElement) {
      sessionStorage.removeItem("isFullscreen"); // Remove fullscreen state
    }
  }, [isFullscreen]);
  //////////// carousel //////////
  const handleCarouselSlide = (event) => {
    const activeItem = event.relatedTarget; // Get the next active slide
    const locationId = activeItem.getAttribute("data-location-id");
    const assetId = activeItem.getAttribute("data-asset-id");

    if (locationId && assetId) {
      const filterMode = Cookies.get("filterMode") || "0";
      getLoadingTemp(filterMode, locationId, assetId);
      getGraphData(filterMode, locationId, assetId);
      getLatestAlerts(filterMode, locationId, assetId);
      getBarData(filterMode, locationId, assetId);

      // Add relays and alerts API call
      fetchRelaysAndAlerts(
        filterMode,
        relaysAndAlertsViewMode,
        locationId,
        assetId
      );
    }
  };

  useEffect(() => {
    const carousel = document.querySelector("#carouselExample");
    carousel.addEventListener("slide.bs.carousel", handleCarouselSlide);

    return () => {
      carousel.removeEventListener("slide.bs.carousel", handleCarouselSlide);
    };
  }, []);

  useEffect(() => {
    if (token) {
      const initialFilterMode = Cookies.get("filterMode") || "0";
      const locationId = locationData[currentPage]?.location_id;
      const assetId = locationData[currentPage]?.assset_id;
      if (locationId && assetId) {
        fetchElectricalParameters(
          initialFilterMode,
          viewMode,
          locationId,
          assetId
        );
      }
    }
  }, [token, viewMode, currentPage, locationData]);

  useEffect(() => {
    if (token) {
      const initialFilterMode = Cookies.get("filterMode") || "0";
      const locationId = locationData[currentPage]?.location_id;
      const assetId = locationData[currentPage]?.assset_id;

      if (locationId && assetId) {
        fetchRelaysAndAlerts(
          initialFilterMode,
          relaysAndAlertsViewMode,
          locationId,
          assetId
        );
      }
    }
  }, [token, relaysAndAlertsViewMode, currentPage, locationData]);

  useEffect(() => {
    // First API call for the initial location
    if (!isHovered){
    const firstItem = locationData[currentPage];
    if (firstItem) {
      const filterMode = Cookies.get("filterMode") || "0";
      getLoadingTemp(filterMode, firstItem.location_id, firstItem.assset_id);
      getGraphData(filterMode, firstItem.location_id, firstItem.assset_id);
      getLatestAlerts(filterMode, firstItem.location_id, firstItem.assset_id);
      getBarData(filterMode, firstItem.location_id, firstItem.assset_id);
    }
    const intervalId = setInterval(() => {
      const nextPage = (currentPage + 1) % locationData.length;
      setCurrentPage(nextPage);
    }, 10000); // Move slide every 30 seconds

    return () => clearInterval(intervalId);
  }
  }, [currentPage, locationData, token,isHovered]);

  // useEffect(() => {
  //   const apiIntervalId = setInterval(() => {
  //     const activeItem = locationData[currentPage];
  //     if (activeItem) {
  //       const filterMode = Cookies.get("filterMode") || "0";
  //       getGraphData(filterMode, activeItem.location_id, activeItem.assset_id);
  //     }
  //   }, 6000); // API call every 5 seconds to pre-load data

  //   return () => clearInterval(apiIntervalId);
  // }, [currentPage, locationData]);

  return (
    <>
      <div
        className={`wrapper ${isFullscreen ? "fullscreen" : ""}`}
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* {isLoading && <TxViewSkeleton />} */}
        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="txViewWpr mdlContent card pr-card mb30">
                    <div
                      className="topBtnCard card pr-card"
                      style={{ marginBottom: "5px" }}
                    >
                      <div className="topBtn inrCardBody">
                        <div className="row">
                          <div className="col-md-3 col-lg-3">
                            <div className="form-group switch float-left">
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  checked={isChecked} // The switch's state reflects isChecked
                                  onChange={(e) =>
                                    changeToggleButton(e.target.checked)
                                  } // Handle toggle change
                                  onBlur={() => {}}
                                  name="isGroupSwitchOn"
                                  disabled={assetCountState <= 1} // Disable the button if assetCount is 1 or less
                                />
                                <span id="toggleParamColor">
                                  {/* Update text color based on switch state */}
                                  <span
                                    style={{
                                      color: isChecked ? "black" : "white",
                                    }} // Change color based on the switch state
                                    onClick={() => handleViewToggle("Fleet")}
                                  >
                                    Fleet View
                                  </span>
                                  <span
                                    style={{
                                      color: isChecked ? "white" : "black",
                                    }} // Change color based on the switch state
                                    onClick={() => handleViewToggle("TX")}
                                  >
                                    TX View
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-6 selectassetWpr form-group">
                            {/* <label>Select Asset</label> */}
                            <select
                              className="minimal form-control"
                              value={selectedAsset || ""} // Dynamically set the selected value
                              onChange={(e) => {
                                const selectedOption = locationData.find(
                                  (item) => item.assset_id === e.target.value
                                );
                                if (selectedOption) {
                                  handleLocationAssetClick(
                                    selectedOption.location_id,
                                    selectedOption.assset_id
                                  );
                                }
                              }}
                            >
                              {locationData.map((item) => (
                                <option
                                  key={item.assset_id}
                                  value={item.assset_id}
                                >
                                  {item.location_and_asset_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3 col-lg-3">
                            <div
                              className="form-group switch float-right"
                              style={{ marginRight: "5px" }}
                            >
                              <label className="switch-light">
                                <input
                                  type="checkbox"
                                  name="isGroupSwitchOn"
                                  checked={isCritical}
                                  onChange={(e) =>
                                    handleToggleChange(e.target.checked)
                                  }
                                />
                                <span id="toggleParamColor">
                                  <span
                                    style={{
                                      color: isCritical ? "black" : "white",
                                    }}
                                  >
                                    All
                                  </span>
                                  <span
                                    style={{
                                      color: isCritical ? "white" : "black",
                                    }}
                                  >
                                    Critical
                                  </span>
                                </span>
                                <a className="btn btn-primary"></a>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mdlContent card-body fleetWpr">
                      {/* <div className="col-md-12 col-lg-12"> */}

                      <div
                        id="carouselExample"
                        className="carousel slide w-100"
                        data-bs-ride="false"
                        // data-bs-interval="30000"
                     
                        onMouseEnter={handleMouseEnter} // Pause on hover
                        onMouseLeave={handleMouseLeave} // Resume when hover ends
                      >
                        {/* <div className="carousel-indicators">
                            {locationData.map((_, index) => (
                              <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide-to={index}
                                className={
                                  index === currentPage ? "active" : ""
                                }
                                onClick={() => setCurrentPage(index)}
                              ></button>
                            ))}
                          </div> */}

                        {!isFullscreen && (
                          <div className="fullScreenWpr">
                            <a href="#" onClick={enterFullScreen}>
                              <img src={Image} width={"40"} alt="fullScreen" />
                            </a>
                          </div>
                        )}

                        {isFullscreen && (
                          <div className="fullScreenExit">
                            <a href="#" onClick={exitFullScreen}>
                              <img
                                src={exitImage}
                                width={"40"}
                                alt="exit fullScreen"
                              />
                            </a>
                          </div>
                        )}

                        <div className="carousel-inner">
                          {locationData.map((item, index) => (
                            <div
                              key={item.assset_id}
                              className={`carousel-item ${
                                index === currentPage ? "active" : ""
                              }`}
                              data-location-id={item.location_id}
                              data-asset-id={item.assset_id}
                            >
                              <div className="chartGrp col-md-12 col-lg-12">
                                <div className="row">
                                  <div className="col-md-3 col-lg-3">
                                    <div className="card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>
                                          Loading Utilization And Temperature
                                          Stress
                                        </h5>
                                      </div>
                                      <div className="card-body" >
                                        {tooltip.visible && (
                                          <div
                                            style={{
                                              position: "fixed",
                                              top: tooltip.y + 10,
                                              left: tooltip.x + 10,
                                              background: "white", // Light grey background white f0f0f0
                                              border: "1px solid black",
                                              padding: "1px",
                                              borderRadius: "4px",
                                              boxShadow:
                                                "0px 0px 5px rgba(0,0,0,0.2)",
                                              zIndex: 1000,
                                              pointerEvents: "none",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: tooltip.content,
                                            }}
                                          />
                                        )}
                                        {originalSeries &&
                                        originalSeries.some(
                                          (series) =>
                                            series.data &&
                                            series.data.length > 0
                                        ) ? (
                                          <Plot
                                            data={plotData}
                                            config={{
                                              displayModeBar: false,
                                              displaylogo: false,
                                              responsive: true,
                                            }}
                                            layout={{
                                              autosize: true,
                                              margin: {
     l: 50, // Left margin
     r: 50, // Right margin
    t: 20, // Top margin (reduced to move chart up)
    b: 10, // Bottom margin (reduced for less space below chart)
  },
                                              xaxis: {
                                                showticklabels: false,
                                                titlefont: {
                                                  size: window.innerWidth <= 1366 ? 10 : 12, // Smaller font size for smaller screens
                                                  size: window.innerWidth <= 1600 ? 10 : 12,
                                                },
                                                tickfont: {
                                                  size: window.innerWidth <= 1366 ? 10 : 12,
                                                  size: window.innerWidth <= 1600 ? 10 : 12, // Smaller font size for smaller screens
                                                },
                                              },
                                              yaxis: {
                                                title: "Loading (%)",
                                                range: [
                                                  calculateSeriesMinMax("Loading (%)").min * (window.innerWidth <= 1366 ? 0.9 : 1),
                                                  calculateSeriesMinMax("Loading (%)").max * (window.innerWidth <= 1366 ? 1.1 : 1),
                                                ],
                                                titlefont: {
                                                  color: "#000000",
                                                  family:
                                                    "Montserrat, sans-serif",
                                                  weight: 500,
                                                  size: window.innerWidth <= 1366 ? 10   : 12,
                                                  size: window.innerWidth <= 1600 ? 10   : 12,
                                                },
                                                tickfont: {
                                                  size: window.innerWidth <= 1366 ? 10 : 12,  // Decreased font size for y-axis
                                                  size: window.innerWidth <= 1600 ? 10   : 12,
                                                },
                                              },
                                              yaxis2: {
                                                title: "Winding Temp (°C)",
                                                overlaying: "y",
                                                side: "right",
                                                range: [
                                                  calculateSeriesMinMax("Winding Temp (°C)").min * (window.innerWidth <= 1366 ? 0.9 : 1),
                                                  calculateSeriesMinMax("Winding Temp (°C)").max * (window.innerWidth <= 1366 ? 0.9 : 1),
                                                ],
                                                titlefont: {
                                                  color: "#000000",
                                                  family:
                                                    "Montserrat, sans-serif",
                                                  weight: 500,
                                                  size: window.innerWidth <= 1366 ? 9 : 12,
                                                  size: window.innerWidth <= 1600 ? 9 : 12,
                                                },
                                                tickfont: {
                                                  size: window.innerWidth <= 1366 ? 10 : 12,  // Decreased font size for y-axis
                                                  size: window.innerWidth <= 1600 ? 10 : 12,
                                                },
                                              },
                                              boxmode: "group",
                                              boxgap: 0.7,
                                              legend: {
                                                font: {
                                                  color: "#000000",
                                                  family:
                                                    "Montserrat, sans-serif",
                                                  weight: 600,
                                                  size: window.innerWidth <= 1366 ? 7 : 12,
                                                  size: window.innerWidth <= 1600 ? 7 : 12,
                                                },
                                                orientation: "h", // Horizontal layout for the legend
                                                x: 0.5, // Center the legend horizontally
                                                xanchor: "center", // Anchor the legend to the center
                                                y: 1, // Position legend above the chart
                                                yanchor: "bottom", // Anchor the legend to the bottom of the chart
                                              },
                                              showlegend: window.innerWidth > 1600, // Hide legend for 1366px or smaller screens
                                            }}
                                            onHover={handleHover}
                                            onUnhover={handleUnhover}
                                            className="custom-plot-height"
                                            style={{
                                              width: "100%",
                                              height: 210,
                                            }}
                                          />
                                        ) : (
                                          <p className="text-center">
                                            No Loading Utilization and
                                            Temperature Stress data available
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  
                                  {/* <div className={`col-md-${isEnergyConsumptionAvailable ? 6 : 9} col-lg-${isEnergyConsumptionAvailable ? 6 : 9}`}> */}
                                  <div className="col-md-6 col-lg-6">
                                  <div className="card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>Temperature Cockpit</h5>
                                      </div>
                                      <div className="card-body">
                                        {chartSeries &&
                                        chartSeries.length > 0 ? (
                                          <ReactApexChart
                                            options={chartOption}
                                            series={chartSeries}
                                            type="line"
                                            height={350}
                                          />
                                        ) : (
                                          <p className="text-center">
                                            No Temperature Cockpit data
                                            available
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* {isEnergyConsumptionAvailable && ( */}
                                  <div className="col-md-3 col-lg-3">
                                    <div className="card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>
                                          Energy Consumption Benchmark VS
                                          Actuals
                                        </h5>
                                      </div>
                                      <div className="card-body">
                                        <div id="barchart">
                                          {chartDatas &&
                                          chartDatas.length > 0 ? (
                                            <ReactApexChart
                                              options={chartDataMain.options}
                                              series={chartDataMain.series}
                                              type="bar"
                                            />
                                          ) : (
                                            <p className="text-center">
                                              No Energy Consumption Benchmark VS
                                              Actuals data available
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                {/* )} */}
                                </div>
                              </div>

                              <div className="tblGrp col-md-12 col-lg-12">
                                <div className="row">
                                  <div className="col-md-8 col-lg-8">
                                    <div className="alertTbl card pr-card mb30 tableCard">
                                      <div className="inrCardHdr">
                                        <h5>Latest Alerts</h5>
                                      </div>
                                      <div className="inrCardBody">
                                        {alertRows && alertRows.length > 0 ? (
                                          <div className="table-responsive">
                                            <table
                                              className="table table-hover dtr-inline"
                                              style={{ width: "100%" }}
                                            >
                                              <thead>
                                                <tr>
                                                  {headerNames.map(
                                                    (header, index) => (
                                                      <th key={index}>
                                                        {header}
                                                      </th>
                                                    )
                                                  )}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {reorderedRows.map(
                                                  (row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                      {row.map(
                                                        (cell, cellIndex) => (
                                                          <td key={cellIndex}>
                                                            {reorderedHeaders[
                                                              cellIndex
                                                            ] === "datetime" ? (
                                                              <a href="#">
                                                                {convertTimestamp(
                                                                  cell
                                                                )}
                                                              </a>
                                                            ) : (
                                                              <a href="#">
                                                                {cell}
                                                              </a>
                                                            )}
                                                          </td>
                                                        )
                                                      )}
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <p className="text-center">
                                            No alerts data available
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="eleParaTbl card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>Electrical Parameters Cockpit</h5>
                                        <div className="form-group switch float-right">
                                          <label className="switch-light">
                                            <input
                                              type="checkbox"
                                              name="isGroupSwitchOn"
                                              checked={viewMode === "real_time"} // Reflect the current state
                                              onChange={
                                                handleViewModeToforElectrical
                                              } // Call the toggle handler
                                            />
                                            <span id="toggleParamColor">
                                              <span
                                                style={{
                                                  color:
                                                    viewMode === "24_hour"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                24 Hour
                                              </span>
                                              <span
                                                style={{
                                                  color:
                                                    viewMode === "real_time"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                Real Time
                                              </span>
                                            </span>
                                            <a className="btn btn-primary"></a>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="inrCardBody">
                                        {isElectricalLoading ? (
                                          <ElectricalSkeleton /> // Show skeleton only for Electrical Parameters
                                        ) : electricalParams &&
                                          electricalParams.length > 0 ? (
                                          electricalParams.map(
                                            (paramData, index) => (
                                              <div key={index}>
                                                {/* <h6>{paramData.asset}</h6> */}
                                                <table
                                                  className="table table-bordered dtr-inline"
                                                  style={{ width: "100%" }}
                                                >
                                                  <tbody>
                                                    {Object.entries(
                                                      paramData
                                                    ).map(([key, value]) => {
                                                      // Skip the "asset" key
                                                      if (key === "asset")
                                                        return null;
                                                      return (
                                                        <tr key={key}>
                                                          <th className="">
                                                            {key}
                                                          </th>
                                                          <td
                                                            className={`text-center ${
                                                              value.color || ""
                                                            } td_width`}
                                                          >
                                                            {typeof value.value ===
                                                            "number"
                                                              ? Number.isInteger(
                                                                  value.value
                                                                )
                                                                ? value.value
                                                                : value.value.toFixed(
                                                                    2
                                                                  )
                                                              : value.value ===
                                                                  undefined ||
                                                                value.value ===
                                                                  null
                                                              ? "-"
                                                              : value.value}
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            )
                                          )
                                        ) : (
                                          <p className="text-center">
                                            No electrical parameters cockpit
                                            data available
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-lg-4">
                                    <div className="relayListTbl card pr-card mb30">
                                      <div className="inrCardHdr">
                                        <h5>Relays</h5>
                                        <div className="form-group switch float-right">
                                          <label className="switch-light">
                                            <input
                                              type="checkbox"
                                              name="isGroupSwitchrelays"
                                              checked={
                                                relaysAndAlertsViewMode ===
                                                "real_time"
                                              }
                                              onChange={handleViewModeToggle} // Call toggle handler
                                            />
                                            <span id="toggleParamColor">
                                              <span
                                                style={{
                                                  color:
                                                    relaysAndAlertsViewMode ===
                                                    "24_hour"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                24 Hour
                                              </span>
                                              <span
                                                style={{
                                                  color:
                                                    relaysAndAlertsViewMode ===
                                                    "real_time"
                                                      ? "white"
                                                      : "black",
                                                }}
                                              >
                                                Real Time
                                              </span>
                                            </span>
                                            <a className="btn btn-primary"></a>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="inrCardBody">
                                        {isRelaysLoading ? (
                                          <RelaySkeleton /> // Show skeleton only for Relays
                                        ) : relaysData &&
                                          relaysData.length > 0 ? (
                                          relaysData.map((relay) => (
                                            <div
                                              key={relay.ASSET}
                                              className="mb-4"
                                            >
                                              {/* <h6>{relay.asset}</h6> */}
                                              <table
                                                className="table table-bordered dtr-inline"
                                                style={{ width: "100%" }}
                                              >
                                                <tbody>
                                                  {Object.entries(relay).map(
                                                    ([key, value]) => {
                                                      // Skip rendering the "asset" key as it's already shown as a heading
                                                      if (key === "ASSET")
                                                        return null;
                                                      return (
                                                        <tr key={key}>
                                                          <th className="">
                                                            {key
                                                              }
                                                          </th>
                                                          <td
                                                            className={`text-center ${
                                                              value.color || ""
                                                            } td_width`}
                                                          >
                                                            {value.value || "-"}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          ))
                                        ) : (
                                          <p className="text-center">
                                            No relays data available
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default TxView;
