import React from "react";
import Skeleton from "@mui/material/Skeleton";

const RelaySkeleton = () => (
  <div className="relay-table">
    {/* <div className="inrCardHdr">
      <Skeleton variant="text" width={120} height={25} className="skeleton-title" />
    </div> */}
    <div className="card-body">
      <Skeleton variant="rectangular" width="100%" height={400} className="skeleton-table" />
    </div>
  </div>
);

export default RelaySkeleton;
